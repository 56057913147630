.navbar {
  background: #c0c0c0;

  height: 80px;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0px 2px 2px 1px rgba(0, 126, 138, 0.2);

  .navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    // margin-top: 12px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    overflow: hidden;
  }

  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    // align-items: center;
  }

  img {
    margin-top: 6px;
  }

  .right {
    height: 100%;
  }

  .nav-menu {
    display: flex;
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    align-items: center;
    height: 100%;
    // width: 60vw;
    // justify-content: flex-end;
    margin-right: 2rem;
  }

  .nav-links {
    color: #000;
    font-weight: 500;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease-in-out;
  }
  .nav-links:hover {
    border-bottom: 3px solid #000;
    transition: all 0.2s ease-out;
  }

  .menu-icon {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #c0c0c0;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    height: 30%;
    display: table;
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  #drop-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
}
