@import '../../global.scss';

.main {
  background-image: url(../../assets/background.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 130px;
  height: 200px;

  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1450px;
    margin: 0 auto;

    height: 80vh;

    // max-width: 1200px;
    // max-height: calc(100% - 70px);

    .left {
      flex: 0.5;
      display: flex;
      justify-content: center;
      align-items: center;
      // margin-left: 130px;
      overflow: hidden;

      img {
        align-items: center;
        border-radius: 10px;
      }
      .image1 {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        position: relative;
        z-index: 2;
      }

      .img2 {
        position: absolute;
        border-radius: 10px;

        // top: 0;
        img {
          border-radius: 10px;
        }
      }
    }
  }

  .right {
    flex: 0.4;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    margin-left: 0px;
    // margin-right: 30px;

    h1 {
      font-size: 55px;
      font-weight: 700;
      color: $textColor;
      margin-bottom: 10px;
      word-spacing: 10px;
      letter-spacing: 50;
    }

    h2 {
      font-size: 35px;
      font-weight: 500;
      color: $textColor;
      margin-bottom: 10px;
    }

    h4 {
      font-size: 30px;
      font-weight: 500;
      color: $textColor;

      span {
        color: #e02042;
      }

      .ityped-cursor {
        animation: blink 1s infinite;
      }

      @keyframes blink {
        50% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
    a {
      position: absolute;
      bottom: 10px;
      left: 50%;

      img {
        width: 35px;
        animation: arrowBlink 2s infinite;

        @keyframes arrowBlink {
          100% {
            opacity: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1900px) {
  .main {
    .wrapper {
      .left {
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-left: 130px;
        overflow: hidden;
        // margin-right: 100px;

        img {
          align-items: center;
          height: 500px;
          border-radius: 10px;
        }
        .image1 {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          position: relative;
          z-index: 2;
        }

        .img2 {
          position: absolute;
          border-radius: 10px;
          img {
            border-radius: 10px;
            height: 550px;
          }
        }
      }
    }

    .right {
      flex: 0.4;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      margin-left: 0px;
      // margin-right: 30px;

      h1 {
        font-size: 65px;
        font-weight: 700;
        color: $textColor;
        margin-bottom: 10px;
        word-spacing: 10px;
        letter-spacing: 50;
      }

      h2 {
        font-size: 40px;
        font-weight: 500;
        color: $textColor;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 40px;
        font-weight: 500;
        color: $textColor;

        span {
          color: #e02042;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
      a {
        position: absolute;
        bottom: 10px;
        left: 50%;

        img {
          width: 35px;
          animation: arrowBlink 2s infinite;

          @keyframes arrowBlink {
            100% {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .main {
    .wrapper {
      .left {
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-left: 130px;
        overflow: hidden;

        img {
          align-items: center;
          border-radius: 10px;
        }
        .image1 {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          position: relative;
          z-index: 2;
          img {
            height: 300px;
            width: 250px;
          }
        }

        .img2 {
          position: absolute;
          border-radius: 10px;

          // top: 0;
          img {
            border-radius: 10px;
            height: 320px;
            width: 300px;
          }
        }
      }
    }

    .right {
      flex: 0.4;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      margin-left: 0px;
      // margin-right: 30px;

      h1 {
        font-size: 35px;
        font-weight: 700;
        color: $textColor;
        margin-bottom: 10px;
        word-spacing: 10px;
        letter-spacing: 50;
      }

      h2 {
        font-size: 25px;
        font-weight: 500;
        color: $textColor;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 25px;
        font-weight: 500;
        color: $textColor;

        span {
          color: #e02042;
        }

        .ityped-cursor {
          animation: blink 1s infinite;
        }

        @keyframes blink {
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }
      }
      a {
        position: absolute;
        bottom: 10px;
        left: 50%;

        img {
          width: 35px;
          animation: arrowBlink 2s infinite;

          @keyframes arrowBlink {
            100% {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .main {
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1450px;
      margin: 0 auto;
      flex-direction: column;

      height: 80vh;

      // max-width: 1200px;
      // max-height: calc(100% - 70px);

      .left {
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-left: 130px;
        overflow: hidden;

        img {
          align-items: center;
          border-radius: 10px;
        }
        .image1 {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          position: relative;
          z-index: 2;
          img {
            height: 300px;
            width: 250px;
          }
        }

        .img2 {
          position: absolute;
          border-radius: 10px;

          // top: 0;
          img {
            height: 320px;
            width: 300px;
          }
        }
      }
    }

    .right {
      flex: 0.4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      width: 100%;
      // margin-right: 30px;

      h1 {
        font-size: 40px;
        font-weight: 700;
        color: $textColor;
        margin-bottom: 10px;
        word-spacing: 10px;
        letter-spacing: 50;
      }

      h2 {
        font-size: 30px;
        font-weight: 500;
        color: $textColor;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 30px;
        font-weight: 500;
        color: $textColor;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .main {
    .wrapper {
      .left {
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-left: 130px;
        overflow: hidden;
        margin-top: 15px;

        img {
          align-items: center;
          border-radius: 10px;
        }
        .image1 {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          position: relative;
          z-index: 2;
          img {
            height: 280px;
            width: 220px;
          }
        }

        .img2 {
          position: absolute;
          border-radius: 10px;

          // top: 0;
          img {
            height: 300px;
            width: 280px;
          }
        }
      }
    }

    .right {
      flex: 0.4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      width: 100%;
      // margin-right: 30px;

      h1 {
        font-size: 30px;
        font-weight: 700;
        color: $textColor;
        margin-bottom: 10px;
        word-spacing: 10px;
        letter-spacing: 50;
      }

      h2 {
        font-size: 25px;
        font-weight: 500;
        color: $textColor;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 25px;
        font-weight: 500;
        color: $textColor;
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .main {
    .wrapper {
      .left {
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-left: 130px;
        overflow: hidden;
        margin-top: 20px;

        img {
          align-items: center;
          border-radius: 10px;
        }
        .image1 {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          position: relative;
          z-index: 2;
          img {
            height: 210px;
            width: 170px;
          }
        }

        .img2 {
          position: absolute;
          border-radius: 10px;

          // top: 0;
          img {
            height: 225px;
            width: 220px;
          }
        }
      }
    }

    .right {
      flex: 0.4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 0px;
      width: 100%;
      // margin-right: 30px;

      h1 {
        font-size: 30px;
        font-weight: 700;
        color: $textColor;
        margin-bottom: 10px;
        word-spacing: 10px;
        letter-spacing: 50;
      }

      h2 {
        font-size: 20px;
        font-weight: 500;
        color: $textColor;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 20px;
        font-weight: 500;
        color: $textColor;
      }
    }
  }
}

// .dark {
//   background-color: #333333;
//   background-image: url(../../assets/background.svg);
//   background-position: left top;
//   background-repeat: no-repeat;
//   background-size: cover;
//   width: 100px;
//   height: 190px;

//   .wrapper {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     max-width: 1450px;
//     margin: 0 auto;

//     height: 80vh;

//     // max-width: 1200px;
//     // max-height: calc(100% - 70px);

//     .left {
//       flex: 0.5;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       // margin-left: 130px;

//       img {
//         align-items: center;
//       }
//       .image1 {
//         // display: flex;
//         // justify-content: center;
//         // align-items: center;
//         position: relative;
//         z-index: 2;
//       }

//       .img2 {
//         position: absolute;
//         // top: 0;
//       }
//     }
//   }

//   .right {
//     flex: 0.4;
//     display: flex;
//     flex-direction: column;
//     // align-items: center;
//     justify-content: center;
//     margin-left: 0px;
//     // margin-right: 30px;

//     h1 {
//       font-size: 55px;
//       font-weight: 700;
//       color: $headerColor;
//       margin-bottom: 10px;
//       word-spacing: 10px;
//       letter-spacing: 50;
//     }

//     h2 {
//       font-size: 35px;
//       font-weight: 500;
//       color: $headerColor;
//       margin-bottom: 10px;
//     }

//     h4 {
//       font-size: 30px;
//       font-weight: 500;
//       color: $headerColor;

//       span {
//         color: #e02042;
//       }

//       .ityped-cursor {
//         animation: blink 1s infinite;
//       }

//       @keyframes blink {
//         50% {
//           opacity: 1;
//         }
//         100% {
//           opacity: 0;
//         }
//       }
//     }
//     a {
//       position: absolute;
//       bottom: 10px;
//       left: 50%;

//       img {
//         width: 35px;
//         animation: arrowBlink 2s infinite;

//         @keyframes arrowBlink {
//           100% {
//             opacity: 0;
//           }
//         }
//       }
//     }
//   }
// }
