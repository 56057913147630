@import '../../global.scss';

.projetosList {
  font-size: 14px;
  margin-right: 50px;
  padding: 7px;
  border-radius: 10px;
  cursor: pointer;

  // @include mobile{
  //   margin-right: 20px;
  // }

  &.active {
    background-color: $mainColor;
    color: white;
  }
}

@media screen and (max-width: 1200px) {
  .projetosList {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-right: 10px;
    padding: 7px;
    border-radius: 10px;
    height: 25px;
    cursor: pointer;

    // @include mobile{
    //   margin-right: 20px;
    // }

    &.active {
      background-color: $mainColor;
      color: white;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-right: 10px;

      border-radius: 10px;
      height: 20px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 450px) {
  .projetosList {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-right: 10px;
    padding: 2px;
    border-radius: 10px;
    height: 25px;
    cursor: pointer;

    // @include mobile{
    //   margin-right: 20px;
    // }

    &.active {
      background-color: $mainColor;
      color: white;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-right: 10px;

      border-radius: 10px;
      height: 20px;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 320px) {
  .projetosList {
    font-size: 12px;
    margin-right: 10px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;

    // @include mobile{
    //   margin-right: 20px;
    // }

    &.active {
      background-color: $mainColor;
      color: white;
    }
  }
}
