@import '../../global.scss';

.about {
  background: linear-gradient(to bottom, #75c1c7, #00585e);
  // background: linear-gradient(to bottom, #f7f7f7, #75c1c7);
  // background: linear-gradient(to bottom, #ffffff, #575757);
  // opacity: 62%;

  .wrapper {
    display: flex;
    // justify-content: center;
    align-items: center;
    max-width: 1200px;
    max-height: 800px;
    margin: 0 auto;

    .left {
      flex: 0.5;
      overflow: hidden;

      .content {
        max-width: 655px;

        margin: 0 auto;
        margin-left: 85px;

        h2 {
          margin-top: 80px;
          font-weight: 700;
          margin-bottom: 30px;
        }

        p {
          font-size: 20px;
          margin: 20px 0;
          text-align: left;

          a {
            font-weight: 500;
            color: #000;
            margin-left: 5px;
            text-decoration: underline;
          }
        }

        h3 {
          margin-bottom: 20px;
        }

        .btn-ct {
          width: 200px;
          height: 40px;
          border-radius: 10px;
          border: 0;
          cursor: pointer;
          background: $mainColor;
          color: #fff;
          transition: filter 0.2s;
        }

        button:hover {
          filter: brightness(0.8);
        }

        .btn-cv {
          width: 200px;
          height: 40px;
          border-radius: 10px;
          border: 0;
          cursor: pointer;
          background: #e5353c;
          color: #fff;
          margin-left: 30px;
          transition: filter 0.2s;
        }

        button:hover {
          filter: brightness(0.8);
        }
      }
    }

    .right {
      flex: 0.5;

      .skills {
        width: 400px;
        height: 350px;
        margin-left: 120px;
        margin-top: 90px;
        // border: 1px solid #000;
        background: #fff;
        border-radius: 10px;
        position: relative;
        z-index: 2;
      }

      .back {
        position: absolute;
        margin-top: -387px;

        margin-left: 117px;
        img {
          width: 445px;
          height: 390px;
        }
      }

      .conteudo {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // margin-left: 30px;
        margin: 10px 0px 10px 40px;

        .col {
          display: flex;
          line-height: 35px;
          margin-top: 10px;

          p {
            font-size: 20px;
            // line-height: 20px;
          }

          .first-col {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1900px) {
  .about {
    background: linear-gradient(to bottom, #75c1c7, #00585e);
    // background: linear-gradient(to bottom, #f7f7f7, #75c1c7);
    // background: linear-gradient(to bottom, #ffffff, #575757);
    // opacity: 62%;

    .wrapper {
      display: flex;
      // justify-content: center;
      align-items: center;
      max-width: 1800px;
      height: 80vh;
      margin: 0 auto;

      .left {
        flex: 0.7;
        overflow: hidden;
        margin-left: 100px;

        .content {
          max-width: 800px;

          margin: 0 auto;
          margin-left: 100px;

          h2 {
            // margin-top: 80px;
            font-weight: 700;
            margin-bottom: 30px;
            font-size: 30px;
          }

          p {
            font-size: 25px;
            margin: 20px 0;
            // text-align: left;
            text-align: justify;

            a {
              font-weight: 500;
              color: #000;
              margin-left: 5px;
              text-decoration: underline;
            }
          }

          h3 {
            margin-bottom: 20px;
            font-size: 25px;
          }

          .btn-ct {
            width: 300px;
            height: 60px;
            border-radius: 10px;
            border: 0;
            font-size: 18px;
            // font-weight: bold;
            cursor: pointer;
            background: $mainColor;
            color: #fff;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }

          .btn-cv {
            width: 300px;
            height: 60px;
            border-radius: 10px;
            border: 0;
            font-size: 18px;
            // font-weight: bold;
            cursor: pointer;
            background: #e5353c;
            color: #fff;
            margin-left: 30px;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }
        }
      }

      .right {
        flex: 0.5;

        .skills {
          width: 440px;
          height: 420px;
          // margin-left: 100px;
          margin-top: 90px;
          // border: 1px solid #000;
          background: #fff;
          border-radius: 10px;
          position: relative;
          z-index: 2;
        }

        .back {
          position: absolute;
          margin-top: -470px;

          // margin-left: 117px;
          img {
            width: 490px;
            height: 475px;
          }
        }

        .conteudo {
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // margin-left: 30px;
          margin: 10px 0px 10px 40px;

          .col {
            display: flex;
            line-height: 50px;
            margin-top: 10px;

            p {
              font-size: 25px;
              // line-height: 20px;
            }

            .first-col {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .about {
    background: linear-gradient(to bottom, #75c1c7, #00585e);
    // background: linear-gradient(to bottom, #f7f7f7, #75c1c7);
    // background: linear-gradient(to bottom, #ffffff, #575757);
    // opacity: 62%;

    .wrapper {
      display: flex;
      // justify-content: center;
      align-items: center;
      max-width: 1200px;
      max-height: 800px;
      margin: 0 auto;

      .left {
        flex: 0.5;
        overflow: hidden;

        .content {
          max-width: 655px;

          margin: 0 auto;
          margin-left: 85px;

          h2 {
            margin-top: 80px;
            font-weight: 700;
            margin-bottom: 30px;
          }

          p {
            font-size: 20px;
            margin: 20px 0;
            text-align: left;

            a {
              font-weight: 500;
              color: #000;
              margin-left: 5px;
              text-decoration: underline;
            }
          }

          h3 {
            margin-bottom: 20px;
          }

          .btn-ct {
            width: 200px;
            height: 40px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: $mainColor;
            color: #fff;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }

          .btn-cv {
            width: 200px;
            height: 40px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: #e5353c;
            color: #fff;
            margin-left: 30px;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }
        }
      }

      .right {
        flex: 0.5;

        .skills {
          width: 400px;
          height: 350px;
          margin-left: 120px;
          margin-top: 90px;
          // border: 1px solid #000;
          background: #fff;
          border-radius: 10px;
          position: relative;
          z-index: 2;
        }

        .back {
          position: absolute;
          margin-top: -387px;

          margin-left: 117px;
          img {
            width: 445px;
            height: 390px;
          }
        }

        .conteudo {
          // display: flex;
          // flex-direction: column;
          // align-items: center;
          // margin-left: 30px;
          margin: 10px 0px 10px 40px;

          .col {
            display: flex;
            line-height: 35px;
            margin-top: 10px;

            p {
              font-size: 20px;
              // line-height: 20px;
            }

            .first-col {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .about {
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      max-width: 1000px;
      max-height: 800px;

      .left {
        display: flex;
        flex: 0.5;
        overflow: hidden;

        .content {
          max-width: 600px;
          height: 400px;
          margin-left: 40px;

          h2 {
            margin-top: 30px;
            font-weight: 700;
            margin-bottom: 30px;
            // text-align: center;
            font-size: 25px;
          }

          p {
            font-size: 15px;
            margin: 20px 0;
            text-align: justify;

            a {
              font-weight: 500;
              color: #000;
              margin-left: 5px;
              text-decoration: underline;
            }
          }

          h3 {
            margin-bottom: 20px;
            font-size: 15px;
          }

          .btn-ct {
            width: 130px;
            height: 40px;
            border-radius: 10px;
            border: 0;
            font-size: 12px;
            cursor: pointer;
            background: $mainColor;
            color: #fff;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }

          .btn-cv {
            width: 130px;
            height: 40px;
            border-radius: 10px;
            border: 0;
            font-size: 12px;
            cursor: pointer;
            background: #e02042;
            color: #fff;
            margin-left: 30px;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }
        }
      }

      .right {
        flex: 0.6;
        // display: flex;
        margin-bottom: 10px;
        // margin-right: 50px;

        .skills {
          width: 400px;
          height: 320px;

          background: #fff;
          border-radius: 30px;
        }

        .back {
          display: none;
        }

        .conteudo {
          margin: 10px 0px 10px 40px;

          .col {
            display: flex;
            line-height: 35px;
            margin-top: 10px;

            p {
              font-size: 20px;
            }

            .first-col {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .about {
    .wrapper {
      flex-direction: column;
      overflow: hidden;
      .left {
        flex: 0.5;

        .content {
          max-width: 350px;
          height: 360px;
          margin-left: 2px;
          // margin-bottom: 350px;

          h2 {
            font-size: 18px;
            margin-top: 10px;
            font-weight: 700;
            margin-bottom: 5px;
            text-align: center;
          }

          p {
            font-size: 15px;
            margin: 10px 5px;
            text-align: justify;

            a {
              font-weight: 500;
              color: #000;
              margin-left: 5px;
              text-decoration: underline;
            }
          }

          h3 {
            margin-bottom: 15px;
            font-size: 17px;
          }

          .btn-ct {
            width: 135px;
            height: 34px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: $mainColor;
            color: #fff;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }

          .btn-cv {
            width: 135px;
            height: 34px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: #e02042;
            color: #fff;
            margin-left: 30px;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: center;
        .skills {
          width: 320px;
          height: 225px;
          margin-left: 25px;
          margin-top: 0px;
          background: #fff;
          border-radius: 30px;
          position: relative;
          z-index: 2;
        }

        .back {
          display: none;
          // position: absolute;
          // margin-top: -20px;

          // margin-left: 25px;
          // img {
          //   width: 320px;
          //   height: 225px;
          //   border-radius: 30px;
          // }
        }

        .conteudo {
          margin: 8px 0px 10px 40px;

          h2 {
            font-size: 18px;
          }

          .col {
            display: flex;
            line-height: 28px;
            margin-top: 10px;

            p {
              font-size: 15px;
            }

            .first-col {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .about {
    .wrapper {
      .left {
        flex: 0.5;

        .content {
          max-width: 350px;
          height: 370px;
          margin: 0 auto;
          margin-left: 2px;
          // margin-bottom: 350px;

          h2 {
            font-size: 18px;
            margin-top: 15px;
            font-weight: 700;
            margin-bottom: 15px;
            text-align: center;
          }

          p {
            font-size: 15px;
            margin: 10px 5px;
            text-align: left;

            a {
              font-weight: 500;
              color: #000;
              margin-left: 5px;
              text-decoration: underline;
            }
          }

          h3 {
            margin-bottom: 20px;
            font-size: 17px;
          }

          .btn-ct {
            width: 135px;
            height: 34px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: $mainColor;
            color: #fff;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }

          .btn-cv {
            width: 135px;
            height: 34px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: #e02042;
            color: #fff;
            margin-left: 30px;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }
        }
      }

      .right {
        .skills {
          width: 320px;
          height: 230px;
          margin-left: 0px;
          margin-top: 0px;
          background: #fff;
          border-radius: 30px;
          position: relative;
          z-index: 2;
        }

        .conteudo {
          margin: 10px 0px 10px 40px;

          h2 {
            font-size: 18px;
          }

          .col {
            display: flex;
            line-height: 28px;
            margin-top: 5px;

            p {
              font-size: 15px;
            }

            .first-col {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .about {
    .wrapper {
      .left {
        flex: 0.5;

        .content {
          max-width: 350px;
          height: 370px;
          margin: 0 auto;
          margin-left: 2px;
          // margin-bottom: 350px;

          h2 {
            font-size: 18px;
            margin-top: 15px;
            font-weight: 700;
            margin-bottom: 15px;
            text-align: center;
          }

          p {
            font-size: 15px;
            margin: 10px 5px;
            text-align: left;

            a {
              font-weight: 500;
              color: #000;
              margin-left: 5px;
              text-decoration: underline;
            }
          }

          h3 {
            margin-bottom: 20px;
            font-size: 17px;
          }

          .btn-ct {
            width: 135px;
            height: 34px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: $mainColor;
            color: #fff;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }

          .btn-cv {
            width: 135px;
            height: 34px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: #e02042;
            color: #fff;
            margin-left: 30px;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }
        }
      }

      .right {
        .skills {
          width: 320px;
          height: 195px;
          margin-left: 0px;
          margin-top: 0px;
          background: #fff;
          border-radius: 30px;
          position: relative;
          z-index: 2;
        }

        .conteudo {
          margin: 0px 0px 10px 40px;

          h2 {
            font-size: 15px;
          }

          .col {
            display: flex;
            line-height: 25px;
            margin-top: 10px;

            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .about {
    .wrapper {
      .left {
        flex: 0.5;

        .content {
          max-width: 350px;
          height: 370px;
          margin: 0 auto;
          margin-left: 2px;
          // margin-bottom: 350px;

          h2 {
            font-size: 18px;
            margin-top: 15px;
            font-weight: 700;
            margin-bottom: 15px;
            text-align: center;
          }

          p {
            font-size: 15px;
            margin: 10px 5px;
            text-align: left;

            a {
              font-weight: 500;
              color: #000;
              margin-left: 5px;
              text-decoration: underline;
            }
          }

          h3 {
            margin-bottom: 20px;
            font-size: 17px;
          }

          .btn-ct {
            width: 135px;
            height: 34px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: $mainColor;
            color: #fff;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }

          .btn-cv {
            width: 135px;
            height: 34px;
            border-radius: 10px;
            border: 0;
            cursor: pointer;
            background: #e02042;
            color: #fff;
            margin-left: 30px;
            transition: filter 0.2s;
          }

          button:hover {
            filter: brightness(0.8);
          }
        }
      }

      .right {
        .skills {
          width: 300px;
          height: 195px;
          margin-left: 0px;
          margin-top: 0px;
          background: #fff;
          border-radius: 30px;
          position: relative;
          z-index: 2;
        }

        .back {
          position: absolute;
          margin-top: -20px;

          margin-left: 10px;
          img {
            width: 300px;
            height: 195px;
            border-radius: 30px;
          }
        }

        .conteudo {
          margin: 0px 0px 10px 40px;

          h2 {
            font-size: 15px;
          }

          .col {
            display: flex;
            line-height: 20px;
            margin-top: 5px;

            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
