@import '../../global.scss';

.projetos {
  background: linear-gradient(to bottom, #ffffff, #575757);
  display: flex;
  flex-direction: column;
  align-items: center;
  // color: $textColor;

  h1 {
    height: 80px;
    font-size: 50px;
    margin-top: 15px;
    overflow: hidden;

    // @include mobile {
    //   font-size: 20px;
    // }
  }

  ul {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 10px;
    list-style: none;
    display: flex;
    margin-top: 30px;
    overflow: hidden;

    // @include mobile {
    //   margin: 10px 0;
    //   flex-wrap: wrap;
    //   justify-content: center;
    // }
  }

  .container {
    // width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;

    // @include mobile {
    //   width: 100%;
    // }

    .item {
      width: 318px;
      height: 220px;
      border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all 0.5s ease;
      // cursor: move;

      // @include mobile {
      //   width: 130px;
      //   height: 100px;
      // }

      h3 {
        position: absolute;
        font-size: 20px;
      }
      a {
        text-decoration: none;
        color: white;
        position: absolute;
        margin-top: 80px;
      }

      .url {
        margin-right: 50px;
      }

      .git {
        margin-left: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }

      &:hover {
        background-color: $mainColor;
        img {
          opacity: 0.2;
          z-index: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1900px) {
  .projetos {
    background: linear-gradient(to bottom, #ffffff, #575757);
    display: flex;
    flex-direction: column;
    align-items: center;
    // color: $textColor;

    h1 {
      height: 80px;
      font-size: 60px;
      margin-top: 15px;
      overflow: hidden;

      // @include mobile {
      //   font-size: 20px;
      // }
    }

    ul {
      width: 100%;
      align-items: center;
      justify-content: center;
      margin: 10px;
      list-style: none;
      display: flex;
      margin-top: 30px;
      overflow: hidden;

      // @include mobile {
      //   margin: 10px 0;
      //   flex-wrap: wrap;
      //   justify-content: center;
      // }

      li {
        width: 120px;
        height: 35px;
        text-align: center;
        font-size: 20px;

        // justify-content: center;
        // align-items: center;
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;

      // @include mobile {
      //   width: 100%;
      // }

      .item {
        width: 400px;
        height: 290px;
        border-radius: 20px;
        border: 1px solid rgb(240, 239, 239);
        margin: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        transition: all 0.5s ease;
        // cursor: move;

        // @include mobile {
        //   width: 130px;
        //   height: 100px;
        // }

        h3 {
          position: absolute;
          font-size: 20px;
        }
        a {
          text-decoration: none;
          color: white;
          position: absolute;
          margin-top: 80px;
        }

        .url {
          margin-right: 50px;
        }

        .git {
          margin-left: 50px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }

        &:hover {
          background-color: $mainColor;
          img {
            opacity: 0.2;
            z-index: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .projetos {
    background: linear-gradient(to bottom, #ffffff, #575757);
    display: flex;
    flex-direction: column;
    align-items: center;
    // color: $textColor;

    h1 {
      height: 80px;
      font-size: 60px;
      margin-top: 15px;
      overflow: hidden;

      // @include mobile {
      //   font-size: 20px;
      // }
    }

    ul {
      width: 100%;
      align-items: center;
      justify-content: center;
      margin: 10px;
      list-style: none;
      display: flex;
      margin-top: 30px;
      overflow: hidden;

      // @include mobile {
      //   margin: 10px 0;
      //   flex-wrap: wrap;
      //   justify-content: center;
      // }

      li {
        width: 120px;
        height: 35px;
        text-align: center;
        font-size: 20px;

        // justify-content: center;
        // align-items: center;
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;

      // @include mobile {
      //   width: 100%;
      // }

      .item {
        width: 300px;
        height: 210px;
        border-radius: 20px;
        border: 1px solid rgb(240, 239, 239);
        margin: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        transition: all 0.5s ease;
        // cursor: move;

        // @include mobile {
        //   width: 130px;
        //   height: 100px;
        // }

        h3 {
          position: absolute;
          font-size: 20px;
        }
        a {
          text-decoration: none;
          color: white;
          position: absolute;
          margin-top: 80px;
        }

        .url {
          margin-right: 50px;
        }

        .git {
          margin-left: 50px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }

        &:hover {
          background-color: $mainColor;
          img {
            opacity: 0.2;
            z-index: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .projetos {
    background: linear-gradient(to bottom, #ffffff, #575757);
    display: flex;
    flex-direction: column;
    align-items: center;
    // color: $textColor;

    h1 {
      height: 45px;
      font-size: 30px;
      margin-top: 15px;
      overflow: hidden;

      // @include mobile {
      //   font-size: 20px;
      // }
    }

    ul {
      width: 100%;
      height: 30px;
      align-items: center;
      justify-content: center;
      margin: 10px;
      list-style: none;
      display: flex;
      margin-top: 10px;
      overflow: hidden;

      // @include mobile {
      //   margin: 10px 0;
      //   flex-wrap: wrap;
      //   justify-content: center;
      // }

      li {
        width: 100px;
        height: 35px;
        text-align: center;
        font-size: 15px;

        // justify-content: center;
        // align-items: center;
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 10px;

      // @include mobile {
      //   width: 100%;
      // }

      .item {
        width: 240px;
        height: 170px;
        border-radius: 20px;
        border: 1px solid rgb(240, 239, 239);
        margin: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        transition: all 0.5s ease;
        // cursor: move;

        // @include mobile {
        //   width: 130px;
        //   height: 100px;
        // }

        h3 {
          position: absolute;
          font-size: 20px;
        }
        a {
          text-decoration: none;
          color: white;
          position: absolute;
          margin-top: 80px;
        }

        .url {
          margin-right: 50px;
        }

        .git {
          margin-left: 50px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }

        &:hover {
          background-color: $mainColor;
          img {
            opacity: 0.2;
            z-index: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .projetos {
    background: linear-gradient(to bottom, #ffffff, #575757);
    display: flex;
    flex-direction: column;
    align-items: center;
    // color: $textColor;

    h1 {
      font-size: 40px;
      margin-top: 20px;

      // @include mobile {
      //   font-size: 20px;
      // }
    }

    ul {
      margin: 10px;
      padding: 0;
      list-style: none;
      display: flex;
      margin-top: 30px;

      // @include mobile {
      //   margin: 10px 0;
      //   flex-wrap: wrap;
      //   justify-content: center;
      // }
    }

    .container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;

      // @include mobile {
      //   width: 100%;
      // }

      .item {
        width: 220px;
        height: 150px;
        border-radius: 20px;
        border: 1px solid rgb(240, 239, 239);
        margin: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        transition: all 0.5s ease;
        cursor: pointer;

        // @include mobile {
        //   width: 130px;
        //   height: 100px;
        // }

        h3 {
          position: absolute;
          font-size: 20px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }

        &:hover {
          background-color: $mainColor;
          img {
            opacity: 0.2;
            z-index: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .projetos {
    background: linear-gradient(to bottom, #ffffff, #575757);
    display: flex;
    flex-direction: column;
    align-items: center;
    // color: $textColor;

    h1 {
      font-size: 30px;
      margin-top: 25px;
      height: 80px;

      // @include mobile {
      //   font-size: 20px;
      // }
    }

    ul {
      margin: 10px;
      list-style: none;
      display: flex;
      margin-top: 10px;
      border-radius: 10px;
      width: 90%;
      li {
        font-size: 12px;
      }
    }

    .container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;

      // @include mobile {
      //   width: 100%;
      // }

      .item {
        width: 220px;
        height: 150px;
        border-radius: 20px;
        border: 1px solid rgb(240, 239, 239);
        margin: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        transition: all 0.5s ease;
        cursor: pointer;

        // @include mobile {
        //   width: 130px;
        //   height: 100px;
        // }

        h3 {
          position: absolute;
          font-size: 20px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }

        &:hover {
          background-color: $mainColor;
          img {
            opacity: 0.2;
            z-index: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
  .projetos {
    background: linear-gradient(to bottom, #ffffff, #575757);
    display: flex;
    flex-direction: column;
    align-items: center;
    // color: $textColor;

    h1 {
      font-size: 15px;
      margin-top: 25px;
      height: 80px;

      // @include mobile {
      //   font-size: 20px;
      // }
    }

    ul {
      margin: 10px;
      padding: 0;
      list-style: none;
      display: flex;
      margin-top: 10px;
      height: 90px;

      // @include mobile {
      //   margin: 10px 0;
      //   flex-wrap: wrap;
      //   justify-content: center;
      // }
    }

    .container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 30px;

      // @include mobile {
      //   width: 100%;
      // }

      .item {
        width: 220px;
        height: 150px;
        border-radius: 20px;
        border: 1px solid rgb(240, 239, 239);
        margin: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        transition: all 0.5s ease;
        cursor: pointer;

        // @include mobile {
        //   width: 130px;
        //   height: 100px;
        // }

        h3 {
          position: absolute;
          font-size: 20px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
        }

        &:hover {
          background-color: $mainColor;
          img {
            opacity: 0.2;
            z-index: 0;
          }
        }
      }
    }
  }
}
