.app {
  height: 100vh;
  overflow: hidden;

  .sections {
    width: 100%;
    height: calc(100vh - 70px);
    // height: 100vh;
    // background-color: lightsalmon;
    position: relative;

    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    // overflow: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      width: 100vw;
      height: calc(100vh - 70px);
      scroll-snap-align: start;
    }
  }
}
